<template>
  <div class="home">
    <div class="nav ds_flex al_center">
      <div class="item ds_flex al_center" @click="chooseWeek" :class="[dateType === 'week' ? 'active' : '']">{{week}}</div>
      <div class="item ds_flex al_center" @click="chooseMonth" :class="[dateType === 'month' ? 'active' : '']">{{month}}</div>
    </div>
    <div class="card_list ds_flex al_center">
      <div class="item" :class="[type == 1 && (!userInfo.user.storeId) ? 'active' : '']" @click="chooseType(1)">
        <div class="wrap">
          <p class="t1">开方单量</p>
          <p class="n">{{memberData && memberData.totalCount || 0}}</p>
          <p class="t2"></p>
        </div>
      </div>
      <div class="item" :class="[type == 2 && (!userInfo.user.storeId) ? 'active' : '']" @click="chooseType(2)">
        <div class="wrap">
          <p class="t1">开方药品数</p>
          <p class="n">{{drugData && drugData.totalCount || 0}}</p>
          <p class="t2"></p>
        </div>
      </div>
    </div>
    <div v-if="!userInfo.user.storeId">
      <div v-if="type == 1">
        <div class="title">门店开方单量排行</div>
        <div class="list" v-if="memberData">
          <div class="item ds_flex al_center" v-for="(item, index) in memberData.dataList" :key="item.storeId">
            <div class="left">{{index + 1}}</div>
            <div class="right">
              <div class="txts ds_flex al_center">
                <div class="t1">{{item.storeName}}</div>
                <div class="n">{{(item.prescriptionCount / memberData.totalCount * 100).toFixed(2) + '%'}}</div>
                <div class="amount">{{item.prescriptionCount}}单</div>
              </div>
              <div class="bar">
                <div class="bar_len" :style="{width:item.prescriptionCount / memberData.totalCount * 100 + '%'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="type == 2">
        <div class="title">开方药品排行（前十）</div>
        <div class="list" v-if="drugData">
          <div class="item ds_flex al_center" v-for="(item, index) in drugData.dataList" :key="item.storeId">
            <div class="left">{{index + 1}}</div>
            <div class="right">
              <div class="txts ds_flex al_center">
                <div class="t1">{{item.drugName}}</div>
                <!-- <div class="n">{{(item.drugCount / drugData.totalCount * 100).toFixed(2) + '%'}}</div> -->
                <div class="amount">{{item.drugCount}}{{item.drugUnit}}</div>
              </div>
              <div class="bar">
                <div class="bar_len" :style="{width:(item.drugCount / drugData.totalCount) >= 1 ? '100%' :  (item.drugCount / drugData.totalCount * 100).toFixed(2) + '%'}"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="title">开方药品排行（前十）</div>
      <div class="list" v-if="drugData">
        <div class="item ds_flex al_center" v-for="(item, index) in drugData.dataList" :key="item.storeId">
          <div class="left">{{index + 1}}</div>
          <div class="right">
            <div class="txts ds_flex al_center">
              <div class="t1">{{item.drugName}}</div>
              <!-- <div class="n">{{(item.drugCount / drugData.totalCount * 100).toFixed(2) + '%'}}</div> -->
              <div class="amount">{{item.drugCount}}{{item.drugUnit}}</div>
            </div>
            <div class="bar">
              <div class="bar_len" :style="{width:(item.drugCount / drugData.totalCount) >= 1 ? '100%' :  (item.drugCount / drugData.totalCount * 100).toFixed(2) + '%'}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup v-model="weelyShow" position="bottom" custom-style="height: 20%;" @close="weelyClose">
      <change-week @onConfirm="onConfirmWeek" @cancel="onCancelWeek" :defaults="new Date()" />
    </van-popup>
    <!-- 选择月 -->
    <van-popup v-model="monthShow" position="bottom" custom-style="height: 20%;" @close="weelyClose">
      <van-datetime-picker v-model="currentDate" type="year-month" title="选择年月" :min-date="minDate" @confirm="onConfirmMonth" @cancel="onCancelMonth" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
  </div>
</template>
<script>
let self = null;
import { getDate, getMonthStartAndEnd, getWeekStartAndEnd } from '@/utils/util';
import { setTimeout } from 'timers';
import { Notify } from 'vant';
import changeWeek from '@/components/changeWeek';
import { userInfo } from 'os';
export default {
  components: {
    changeWeek
  },
  data() {
    return {
      chart: null,
      weelyShow: false,
      dateType: 'week',
      weekDate: '',
      type: 1,
      memberData: '',
      drugData: '',
      week: '本周',
      month: '本月',
      monthShow: false, //月显示
      minDate: new Date(2019, 0, 1),
      maxDate: new Date(),
      currentDate: new Date(),
      userInfo: JSON.parse(localStorage.getItem('userInfo')),
    }
  },
  created() {
    self = this;
    this.memberHistoryData(getDate('-', '', false), 'week')
    this.drugHistoryData(getDate('-', '', false), 'week')
  },
  methods: {
    //格式化月日期
    formatter(type, val) {
      if (type === 'year') {
        return `${val}年`;
      } else if (type === 'month') {
        return `${val}月`;
      }
      return val;
    },
    weelyClose() {
      this.weelyShow = false;
      this.monthShow = false;
    },
    // onConfirm
    // 周
    onConfirmWeek(val) {
      console.log(val);
      this.week = val[1].text;
      this.weekDate = val[1].value;
      this.memberHistoryData(val[1].value, 'week')
      this.drugHistoryData(val[1].value, 'week')
      this.weelyShow = false;
    },
    onCancelWeek() {
      this.weelyShow = false;
    },
    /**月
     * onConfirmMonth
     * onCancelMonth
     */
    onConfirmMonth(val) {
      console.log(val);
      this.month = getDate('month', val, false);
      this.memberHistoryData(getDate('-', val, false), 'month');
      this.drugHistoryData(getDate('-', val, false), 'month');
      this.monthShow = false;
    },
    onCancelMonth() {
      this.monthShow = false;
    },
    // 在线处方开方单量周、月数据
    memberHistoryData(date, type) {
      let params = {};
      if (type == 'month') {
        params = {
          currentTime: getDate('month', date, false),
          timeType: 2
        }
      } else {
        params = {
          beginTime: getWeekStartAndEnd(0, date)[0],
          endTime: getWeekStartAndEnd(0, date)[1],
          timeType: 1
        }
      }
      params.enterpriseId = this.userInfo.user.enterpriseId;
      this.$http('get', '/prescription/statistics/getStorePrescriptionCount', params).then(res => {
        console.log('统计数据', res);
        if (res.code == 200) {
          this.memberData = res.data;
        }
      })
    },
    // 在线处方药品统计周、月数据
    drugHistoryData(date, type) {
      let params = {};
      if (type == 'month') {
        params = {
          currentTime: getDate('month', date, false),
          timeType: 2
        }
      } else {
        params = {
          beginTime: getWeekStartAndEnd(0, date)[0],
          endTime: getWeekStartAndEnd(0, date)[1],
          timeType: 1
        }
      }
      if (this.userInfo.user.storeId) {
        params.storeId = this.userInfo.user.storeId;
      } else {
        params.enterpriseId = this.userInfo.user.enterpriseId;
      }
      this.$http('get', '/prescription/statistics/getDrugRankInEnterprise', params).then(res => {
        console.log('统计数据', res);
        if (res.code == 200) {
          this.drugData = res.data;
        }
      })
    },
    // 选择周
    chooseWeek() {
      if (this.dateType == 'week') {
        this.weelyShow = true;
        return;
      }
      this.dateType = 'week';
      if (this.week == 'week') {

        this.memberHistoryData(getDate('-', '', false), 'week');
        this.drugHistoryData(getDate('-', '', false), 'week');
      } else {
        this.memberHistoryData(this.weekDate, 'week');
        this.drugHistoryData(this.weekDate, 'week');
      }
    },
    // 选择月
    chooseMonth() {
      if (this.dateType == 'month') {
        this.monthShow = true;
        return;
      }
      this.dateType = 'month';
      if (this.month == '本月') {
        this.memberHistoryData(getDate('-', '', false), 'month');
        this.drugHistoryData(getDate('-', '', false), 'month');
      } else {
        this.memberHistoryData(this.month, 'month');
        this.drugHistoryData(this.month, 'month');
      }
    },
    // 
    chooseType(type) {
      this.type = type;
    },
  }
}
</script>
<style lang="less" scoped>
.ds_flex {
  display: flex;
  display: -webkit-flex;
}
.ju_center {
  justify-content: center;
  -webkit-justify-content: center;
}
.al_center {
  align-items: center;
  -webkit-align-items: center;
}
.nav {
  padding: 0 0.5rem;
  height: 0.6rem;
  font-size: 0.28rem;
  color: #333;
  border-bottom: 1px solid #f7f6f8;
  .item {
    position: relative;
    padding: 0.1rem 0;
    margin-right: 0.88rem;
  }
  .active {
    color: #3780ff;
    font-size: 0.34rem;
  }
  .active::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    right: -0.35rem;
    transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    width: 0.2rem;
    height: 0.1rem;
    background: url("../../assets/img/btn_dropdown@2x.png") no-repeat center /
      100% 100%;
  }
  .active::before {
    content: "";
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    width: 0.64rem;
    height: 3px;
    background: #3780ff;
    border-radius: 4px;
  }
}
.card_list {
  padding: 0.4rem 0.3rem;
  border-bottom: 0.15rem solid #f8f8f8;
  // margin: .4rem 0;
  .item {
    flex: 1;
    -webkit-flex: 1;
    padding: 0 0.1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-right: 1px solid #dfe5ef;
    text-align: center;
    color: #333333;
    .t1 {
      padding: 0.16rem 0 0.21rem;
      font-size: 0.24rem;
      padding-top: 0.3rem;
      line-height: 0.24rem;
      // color: #fff;
    }
    .n {
      margin-bottom: 0.23rem;
      font-size: 0.6rem;
      line-height: 0.6rem;
      font-weight: bold;
    }
    .t2 {
      padding-bottom: 0.18rem;
      font-size: 0.26rem;
      line-height: 0.26rem;
      .icon {
        display: inline-block;
        margin-left: 0.12rem;
        width: 0.16rem;
        height: 0.23rem;
      }
      .ac_icon_up {
        background: url("../../assets/img/icon_rise@2x.png") no-repeat center /
          100% 100%;
      }
      .ac_icon_down {
        background: url("../../assets/img/icon_reduce@2x.png") no-repeat center /
          100% 100%;
      }
      .green_icon {
        background: url("../../assets/img/icon_rise_nor@2x.png") no-repeat
          center / 100% 100%;
      }
      .red_icon {
        background: url("../../assets/img/icon_reduce_nor@2x.png") no-repeat
          center / 100% 100%;
      }
    }
    .wrap {
      // margin: 0 .1rem;
    }
  }
  .item:last-child {
    border-right: none;
  }
  .active {
    .wrap {
      border-radius: 4px;
      color: #fff;
      background: #3780ff;
    }
  }
}
.title {
  padding: 0.35rem;
  font-weight: 700;
  font-size: 0.3rem;
  color: #1e1f20;
}
.main_echart {
  // padding: .35rem;
  width: 100%;
  height: 6rem;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.list {
  margin: 0 0.3rem 0.3rem;
  padding: 0.5rem;
  box-shadow: 0 0 0.24rem #f5f7fb;
  .item {
    margin-bottom: 0.5rem;
    .left {
      width: 0.5rem;
    }
    .right {
      flex: 1;
      -webkit-flex: 1;
      .txts {
        margin-bottom: 0.25rem;
        color: #333333;
        .t1 {
          flex: 1;
          font-size: 0.28rem;
        }
        .n {
          flex: 1;
          font-size: 0.24rem;
        }
        .amount {
          font-size: 0.24rem;
          color: #00c7c0;
        }
      }
      .bar {
        height: 0.1rem;
        background: #f2f6fa;
        border-radius: 4px;
        .bar_len {
          width: 30%;
          height: 0.1rem;
          background: #3780ff;
          border-radius: 4px;
        }
      }
    }
  }
}
</style>
